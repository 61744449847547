<template>
  <v-card flat>
    <b-row>
      <b-col :md="6">
        <b-form-group
          id="item-serviceTypes-group"
          :label="$t('FORM_LABELS.SERVICE_TYPE')"
          label-for="item-serviceTypes-input"
        >
          <FormSelect
            clearable
            type="select"
            :options="serviceTypes"
            v-model="form.serviceTypeId"
            :validateState="validateState('serviceTypeId')"
          ></FormSelect>
        </b-form-group>
      </b-col>

      <b-col :md="6">
        <b-form-group
          :label="$t('FORM_LABELS.DATE')"
          label-for="item-date-input"
        >
          <FormDatepicker
            type="date"
            :i18n="{
              placeholder: 'FORM_LABELS.DATE'
            }"
            v-model="form.date"
            :validateState="validateState('date')"
          ></FormDatepicker>
        </b-form-group>
      </b-col>

      <b-col :md="6">
        <b-form-group
          id="item-mileage-group"
          v-bind:label="$t('FORM_LABELS.PERIOD_VALUE')"
          label-for="item-mileage-input"
        >
          <FormInput
            type="number"
            :i18n="{
              label: 'FORM_LABELS.PERIOD_VALUE',
              placeholder: $t('FORM_LABELS.PERIOD_VALUE')
            }"
            v-model="form.mileage"
          ></FormInput>
        </b-form-group>
      </b-col>

      <b-col :md="6">
        <b-form-group
          id="item-description-group"
          v-bind:label="$t('FORM_LABELS.DESCRIPTION')"
          label-for="item-description-input"
        >
          <FormInput
            type="text"
            :i18n="{
              label: 'FORM_LABELS.DESCRIPTION',
              placeholder: $t('FORM_LABELS.DESCRIPTION')
            }"
            v-model="form.description"
          ></FormInput>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <div class="col-md-6 d-flex align-center">
        <h3>Разходи ({{ form.serviceExpenses.length }})</h3>
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-primary" @click="handleAddExpense">
          <strong>+ Добави ред</strong>
        </button>
      </div>
    </b-row>

    <ServiceMachineRowsExpense
      class="service-section"
      v-for="(item, i) in form.serviceExpenses"
      :key="i"
      :expense="item"
      :staff="staffList"
      :serviceWarehouseItems="serviceWarehouseItems"
      can-remove
      @remove="handleRemove(i)"
    >
    </ServiceMachineRowsExpense>
    <b-row v-if="form.serviceExpenses.length > 0">
      <div class="col-md-12 text-right">
        <button class="btn btn-primary" @click="handleAddExpense">
          <strong>+ Добави ред</strong>
        </button>
      </div>
    </b-row>
  </v-card>
</template>

<script>
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";
import { FETCH_SERVICE_WAREHOUSE_ITEMS } from "@/modules/service-warehouse-items/store/service-warehouse-items.module";
import { FETCH_SERVICE_TYPES } from "@/modules/service-types/store/service-type.module";

import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import ServiceMachineRowsExpense from "./ServiceMachineRowsExpense";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

export default {
  name: "ServiceMachinesForm",
  mixins: [validationMixin, formBuilderMixin],
  components: {
    FormSelect,
    FormInput,
    FormSwitch,
    FormDatepicker,
    ServiceMachineRowsExpense
  },
  props: {
    form: Object
  },
  validations() {
    const tmpValidationObject = {
      form: {
        date: this.setItemValidations({
          required: true
        }),
        serviceTypeId: this.setItemValidations({
          required: true
        })
      }
    };

    return tmpValidationObject;
  },
  data() {
    return {
      serviceExpensesResults: [],
      serviceExpensesLoading: false,
      options: [],
      serviceWarehouseItems: [],
      serviceTypes: [],
      staffList: [],
      today: new Date().toISOString().slice(0, 10)
    };
  },
  mounted() {
    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then(data => {
      let items = data.data.items;

      this.staffList = items || [];
    });

    this.$store
      .dispatch(
        FETCH_SERVICE_WAREHOUSE_ITEMS
      )
      .then(data => {
        this.serviceWarehouseItems = data.data.items.map(i => {
          return {
            id: i.id,
            name: i.name + " | " + i.warehouse
          };
        });
      });

    this.$store
      .dispatch(
        FETCH_SERVICE_TYPES,
        this.$url.transformParams({ onlySelectValues: true })
      )
      .then(data => {
        this.serviceTypes = data.data.items;
      });
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    handleRemove(i) {
      this.form.serviceExpenses.splice(i, 1);
    },
    handleAddExpense() {
      const lastExpense = {
        date: new Date().toISOString().slice(0, 10),
        machineId: this.form.machineId,
        serviceWarehouseItemId: null,
        staffId: null,
        price: null,
        quantity: null
      };
      this.form.serviceExpenses.push(lastExpense);
    }
  }
};
</script>

<style type="text/css">
.input-lg input {
  font-size: 14px;
  font-weight: bold;
}
.vs__dropdown-toggle {
  background-color: #fff;
}
#item-bol-list-group label {
  font-weight: bold;
  font-size: 15px;
}

.sort_div_holder {
  position: relative;
}
.sort_div {
  display: inline-block;
  padding: 16px 10px;
}
.sort_div_filter {
  position: absolute;
  top: 8px;
  right: -34px;
}

.v-list--dense .v-list-item,
.v-list-item--dense {
  min-height: 20px !important;
  height: 2rem;
}

.v-application--is-ltr .v-list-item__action:first-child,
.v-application--is-ltr .v-list-item__icon:first-child {
  margin-right: 0.5rem !important;
}

.v-list-item--link {
  transition: background-color 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:hover {
  background-color: rgba(0, 0, 0, 0.13);
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 13px !important;
}

.v-data-table > .v-data-table__wrapper > table > thead > th.sortable {
  position: relative;
}
.theme--light.v-data-table
  .v-data-table-header
  th.sortable
  .v-data-table-header__icon {
}
.v-data-table-header__icon {
  height: 1px !important;
  float: right !important;
  top: -27px !important;
  right: -3px;
}
@media (min-width: 1264px) {
  .container {
    max-width: 100% !important;
  }
}

.service-section {
  border: 1px solid #ecf0f3;
  margin-bottom: 5px !important;
  border-radius: 0.42rem;
  -webkit-box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
}
</style>

<template>
  <b-row class="service-section">
    <b-col :md="4">
      <b-form-group :label="$t('FORM_LABELS.DATE')" label-for="item-date-input">
        <FormDatepicker
          type="date"
          :i18n="{
            placeholder: 'FORM_LABELS.DATE'
          }"
          v-model="expense.date"
        ></FormDatepicker>
      </b-form-group>
    </b-col>

    <b-col :md="4">
      <b-form-group
        id="item-serviceWarehouseItems-group"
        :label="$t('FORM_LABELS.WAREHOUSE_ITEM')"
        label-for="item-serviceWarehouseItems-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="serviceWarehouseItems"
          v-model="expense.serviceWarehouseItemId"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :md="4">
      <b-form-group
        id="item-staff-group"
        :label="$t('MENU.ITEM.STAFF.STAFF')"
        label-for="item-staff-input"
      >
        <FormSelect
          clearable
          type="select"
          :options="selectStaffList"
          v-model="expense.staffId"
        ></FormSelect>
      </b-form-group>
    </b-col>

    <b-col :md="6">
      <b-form-group
        id="item-price-group"
        v-bind:label="$t('FORM_LABELS.PRICE')"
        label-for="item-price-input"
      >
        <FormInput
          type="number"
          :i18n="{
            label: 'FORM_LABELS.PRICE',
            placeholder: $t('FORM_LABELS.PRICE')
          }"
          :disabled="!!expense.serviceWarehouseItemId"
          v-model="expense.price"
          :validateState="{}"
        ></FormInput>
      </b-form-group>
    </b-col>

    <b-col :cols="5">
      <b-form-group
        id="item-quantity-group"
        v-bind:label="$t('FORM_LABELS.QTY')"
        label-for="item-quantity-input"
      >
        <FormInput
          type="number"
          :i18n="{
            label: 'FORM_LABELS.QTY',
            placeholder: $t('FORM_LABELS.QTY')
          }"
          v-model="expense.quantity"
          :validateState="{}"
        ></FormInput>
      </b-form-group>
    </b-col>

    <b-col v-if="canRemove" :md="1">
      <b-form-group class="text-right">
        <button class="btn btn-danger mt-6 pr-2 pl-3" @click="$emit('remove')">
          <i class="fa fa-trash"></i>
        </button>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from "@/view/content/forms/components/FormInput.vue";
import FormDatepicker from "@/view/content/forms/components/FormDatepicker.vue";
import FormSelect from "@/view/content/forms/components/FormSelect";

export default {
  name: "FuelExpensesRowCharge",
  components: {
    FormSelect,
    FormInput,
    FormDatepicker
  },
  props: {
    canRemove: Boolean,
    expense: Object,
    staff: Array,
    serviceWarehouseItems: Array
  },
  data() {
    return {
      tempStaffList: null,
      today: new Date().toISOString().slice(0, 10)
    };
  },
  computed: {
    selectStaffList() {
      return this.tempStaffList || this.staff;
    }
  }
};
</script>

<style></style>

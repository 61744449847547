var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('b-row',[_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-serviceTypes-group","label":_vm.$t('FORM_LABELS.SERVICE_TYPE'),"label-for":"item-serviceTypes-input"}},[_c('FormSelect',{attrs:{"clearable":"","type":"select","options":_vm.serviceTypes,"validateState":_vm.validateState('serviceTypeId')},model:{value:(_vm.form.serviceTypeId),callback:function ($$v) {_vm.$set(_vm.form, "serviceTypeId", $$v)},expression:"form.serviceTypeId"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"label":_vm.$t('FORM_LABELS.DATE'),"label-for":"item-date-input"}},[_c('FormDatepicker',{attrs:{"type":"date","i18n":{
            placeholder: 'FORM_LABELS.DATE'
          },"validateState":_vm.validateState('date')},model:{value:(_vm.form.date),callback:function ($$v) {_vm.$set(_vm.form, "date", $$v)},expression:"form.date"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-mileage-group","label":_vm.$t('FORM_LABELS.PERIOD_VALUE'),"label-for":"item-mileage-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
            label: 'FORM_LABELS.PERIOD_VALUE',
            placeholder: _vm.$t('FORM_LABELS.PERIOD_VALUE')
          }},model:{value:(_vm.form.mileage),callback:function ($$v) {_vm.$set(_vm.form, "mileage", $$v)},expression:"form.mileage"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-description-group","label":_vm.$t('FORM_LABELS.DESCRIPTION'),"label-for":"item-description-input"}},[_c('FormInput',{attrs:{"type":"text","i18n":{
            label: 'FORM_LABELS.DESCRIPTION',
            placeholder: _vm.$t('FORM_LABELS.DESCRIPTION')
          }},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1),_c('b-row',[_c('div',{staticClass:"col-md-6 d-flex align-center"},[_c('h3',[_vm._v("Разходи ("+_vm._s(_vm.form.serviceExpenses.length)+")")])]),_c('div',{staticClass:"col-md-6 text-right"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.handleAddExpense}},[_c('strong',[_vm._v("+ Добави ред")])])])]),_vm._l((_vm.form.serviceExpenses),function(item,i){return _c('ServiceMachineRowsExpense',{key:i,staticClass:"service-section",attrs:{"expense":item,"staff":_vm.staffList,"serviceWarehouseItems":_vm.serviceWarehouseItems,"can-remove":""},on:{"remove":function($event){return _vm.handleRemove(i)}}})}),(_vm.form.serviceExpenses.length > 0)?_c('b-row',[_c('div',{staticClass:"col-md-12 text-right"},[_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.handleAddExpense}},[_c('strong',[_vm._v("+ Добави ред")])])])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }
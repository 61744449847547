<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link
            :to="{
              name: 'edit-service-machines',
              params: { id: $route.params.machineId },
              query: $route.query
            }"
          >
            {{ machineName }}
          </router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <ServiceMachineRowsForm
          ref="ServiceMachineRowsForm"
          :form="form"
        ></ServiceMachineRowsForm>

        <v-overlay :value="!firstLoader && isLoadingServiceMachine">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingServiceMachine"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_SERVICE_MACHINE } from "@/modules/service-machines/store/service-machines.module";
import { FETCH_MACHINE_FOR_SERVICE } from "@/modules/service-machines/store/service-machines.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import ServiceMachineRowsForm from "../components/ServiceMachineRowsForm.vue";
import { mapGetters } from "vuex";

export default {
  name: "ServiceMachineRowsAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    ServiceMachineRowsForm
  },
  props: {
    serviceTypeId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      errors: {},
      machine: {},
      form: {
        machineId: null,
        serviceTypeId: null,
        date: new Date().toISOString().slice(0, 10),
        mileage: null,
        description: null,
        serviceExpenses: []
      }
    };
  },
  computed: {
    ...mapGetters(["isLoadingServiceMachine"]),
    machineName() {
      return this.machine.fullName;
    }
  },
  mounted() {
    this.fetchServiceMachine();
    this.form.machineId = this.$route.params.machineId;
  },
  methods: {
    fetchServiceMachine() {
      this.$store
        .dispatch(FETCH_MACHINE_FOR_SERVICE, this.$route.params.machineId)
        .then(data => {
          this.machine = data.data;

          if (this.serviceTypeId) {
            this.form.serviceTypeId = this.serviceTypeId;
          }

          if (this.machine.lastCharge) {
            this.form.mileage = this.machine.lastCharge.mileage;
          }

          this.$store.dispatch(SET_BREADCRUMB, [
            {
              title: this.$i18n.t("MENU.ITEM.SERVICE.MACHINES"),
              route: { name: "list-service-machines", query: this.$route.query }
            },
            {
              title: this.machineName,
              route: {
                name: "edit-service-machines",
                params: { id: this.$route.params.machineId },
                query: this.$route.query
              }
            },
            { title: this.$i18n.t("LABELS.ADD_NEW") }
          ]);
        });
    },
    onSave(next) {
      let vm = this;
      this.$refs.ServiceMachineRowsForm.$v.form.$touch();
      if (this.$refs.ServiceMachineRowsForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly"
        });
        return;
      }

      this.form.machineId = this.$route.params.machineId;
      this.$store
        .dispatch(SAVE_SERVICE_MACHINE, this.form)
        .then(data => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-service-machine-rows",
              params: { id: itemEdit.id },
              query: this.$route.query
            });
          }
          if (next == "new") {
            //New
            this.$router.push({
              name: "add-service-machine-rows",
              query: this.$route.query
            });
          }
          if (next == "exit") {
            this.$router.push({
              name: "edit-service-machines",
              params: { id: this.form.machineId }
            });
          }
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});
          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    }
  }
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"service-section"},[_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"label":_vm.$t('FORM_LABELS.DATE'),"label-for":"item-date-input"}},[_c('FormDatepicker',{attrs:{"type":"date","i18n":{
          placeholder: 'FORM_LABELS.DATE'
        }},model:{value:(_vm.expense.date),callback:function ($$v) {_vm.$set(_vm.expense, "date", $$v)},expression:"expense.date"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-serviceWarehouseItems-group","label":_vm.$t('FORM_LABELS.WAREHOUSE_ITEM'),"label-for":"item-serviceWarehouseItems-input"}},[_c('FormSelect',{attrs:{"clearable":"","type":"select","options":_vm.serviceWarehouseItems},model:{value:(_vm.expense.serviceWarehouseItemId),callback:function ($$v) {_vm.$set(_vm.expense, "serviceWarehouseItemId", $$v)},expression:"expense.serviceWarehouseItemId"}})],1)],1),_c('b-col',{attrs:{"md":4}},[_c('b-form-group',{attrs:{"id":"item-staff-group","label":_vm.$t('MENU.ITEM.STAFF.STAFF'),"label-for":"item-staff-input"}},[_c('FormSelect',{attrs:{"clearable":"","type":"select","options":_vm.selectStaffList},model:{value:(_vm.expense.staffId),callback:function ($$v) {_vm.$set(_vm.expense, "staffId", $$v)},expression:"expense.staffId"}})],1)],1),_c('b-col',{attrs:{"md":6}},[_c('b-form-group',{attrs:{"id":"item-price-group","label":_vm.$t('FORM_LABELS.PRICE'),"label-for":"item-price-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
          label: 'FORM_LABELS.PRICE',
          placeholder: _vm.$t('FORM_LABELS.PRICE')
        },"disabled":!!_vm.expense.serviceWarehouseItemId,"validateState":{}},model:{value:(_vm.expense.price),callback:function ($$v) {_vm.$set(_vm.expense, "price", $$v)},expression:"expense.price"}})],1)],1),_c('b-col',{attrs:{"cols":5}},[_c('b-form-group',{attrs:{"id":"item-quantity-group","label":_vm.$t('FORM_LABELS.QTY'),"label-for":"item-quantity-input"}},[_c('FormInput',{attrs:{"type":"number","i18n":{
          label: 'FORM_LABELS.QTY',
          placeholder: _vm.$t('FORM_LABELS.QTY')
        },"validateState":{}},model:{value:(_vm.expense.quantity),callback:function ($$v) {_vm.$set(_vm.expense, "quantity", $$v)},expression:"expense.quantity"}})],1)],1),(_vm.canRemove)?_c('b-col',{attrs:{"md":1}},[_c('b-form-group',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-danger mt-6 pr-2 pl-3",on:{"click":function($event){return _vm.$emit('remove')}}},[_c('i',{staticClass:"fa fa-trash"})])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }